.back-button {
  font-size: 1.75rem; /* Matches h3 size */
  color: #122632; /* Matches h3 color */
  text-decoration: none; /* Remove underline */
  display: inline; /* Ensures only the text is clickable */
  cursor: pointer;
  transition: box-shadow 0.2s, transform 0.2s;
}

.back-button:active {
  box-shadow: 0 4px 8px #212559;
  transform: translateY(2px); /* Simulates a pressed effect */
}

.back-button:hover {
  color: #212559; /* Optional: Add a hover color */
}
