.note-container {
  padding: 20px;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  background-color: #f7fafc;
}

.note-heading {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.note-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  resize: vertical;
}

.note-submit-button {
  margin-top: 1rem;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.note-submit-button:hover {
  background-color: #0056b3;
}

.note-message {
  margin-top: 1rem;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}
