.InternetTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  background-color: rgb(18, 38, 50);
  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
  padding: 10px;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  min-width: 300px !important;
}
.status-offline {
  color: rgb(241, 100, 101) !important;
}

.status-online {
  color: rgb(27, 163, 169) !important;
}

.status-inactive {
  color: rgb(94, 109, 120) !important;
}

.status-expired {
  color: rgb(250, 169, 77) !important;
}
.status-hold {
  color: rgb(249, 220, 85) !important;
}

.DataGridContainer {
  height: 100%;
  width: 100%;
}

.add-button:hover {
  color: #ffffff !important;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}
.ModalHeader {
  background-color: #233442;
  color: #ffffff;
}

.ModalTitle {
  color: #ffffff;
}

.Subform {
  padding: 20px;
  background-color: #ffffff;
}

/* Styling for the DataGrid */
.MuiDataGrid-root {
  background-color: transparent;
  border: none !important;
}

.MuiDataGrid-columnsContainer {
  background-color: transparent;
}

.MuiDataGrid-columnHeaderTitle {
  color: #ffffff;
  font-weight: bold;
}
.MuiDataGrid-root::-webkit-scrollbar {
  width: 8px !important;
}
.MuiDataGrid-row {
  background-color: transparent;
  color: #ffffff;
}

.MuiCheckbox-root,
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #ffffff;
}

.MuiDataGrid-toolbarContainer {
  background-color: transparent;
}

.MuiDataGrid-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.MuiDataGrid-sortIcon,
.MuiDataGrid-sortIcon svg {
  fill: #ffffff !important;
}

.MuiMenuItem-root,
.MuiMenuItem-root:hover {
  color: #ffffff;
  background-color: #233442;
}

.MuiTextField-root,
.MuiInputLabel-root,
.MuiInputBase-root,
.MuiInputBase-input {
  color: #ffffff;
}

/* Styling for the search input */
.search-input {
  color: #ffffff;
  background-color: #2f4355;
  border: none;
  padding: 8px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 14px;
  width: 200px;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
}
/* Styling for the DataGrid */
.subcheck {
  color: #ffffff !important;
}
.MuiTablePagination-root,
.MuiTablePagination-caption,
.MuiTablePagination-selectRoot,
.MuiTablePagination-select,
.MuiTablePagination-actions button {
  color: #ffffff !important;
}
.MuiDataGrid-root .MuiDataGrid-row:nth-child(odd) {
  background-color: rgba(
    18,
    38,
    50,
    0.2
  ) !important; /* Alternate background color */
}

.MuiDataGrid-root .MuiDataGrid-row:nth-child(even) {
  background-color: rgb(
    50,
    65,
    80
  ) !important; /* Another alternate background color */
}
