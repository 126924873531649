/* Add your existing CSS rules here */

.collect-container {
  padding: 20px;
}

.collect-heading {
  margin-bottom: 1rem;
}

.collect-form {
  padding: 20px;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  background-color: #f7fafc;
}

.collect-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.collect-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.collect-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.converted-amount {
  margin-top: 0.5rem;
}

.collect-submit {
  margin-top: 1rem;
}

.receipt-modal {
  padding: 20px;
}
.darker-bg {
  background-color: #e2e8f0; /* Change this color as needed */
}
