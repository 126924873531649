/* Statement Container */
.testi {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  overflow-x: auto;
}

/* Modal Content */
.mod {
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Table Styles */
.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.9rem;
  text-align: left;
}

.table thead {
  background-color: rgb(18, 38, 50);
  color: #ffffff;
}

.table th,
.table td {
  padding: 12px;
  border-bottom: 1px solid #dddddd;
}

.table th {
  text-transform: uppercase;
  font-weight: bold;
}




.print-button {
  display: flex;
  justify-content: left;
}
