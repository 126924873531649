/* Add your custom styles here */
.order {
  overflow: scroll;
  color: aliceblue;
  background-color: rgb(50, 65, 80);
  height: 100vh;
}
/* Example styles for better visualization, replace with your actual styles */
.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4d5361;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
}

.order-body {
  margin-top: 20px;
  background-color: #4d5361;
  display: flex;
  margin: 10px;
  border-radius: 5px;
}

.table-container-products,
.table-container-services {
  flex: 1;
  margin: 10px;
  background-color: #4d5361;
}

/* Additional styles for modal */
.modal-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* You can customize the modal styles based on your design */

.buttonWithMargin {
  margin-top: 10px !important;
}
.submit {
  margin-left: 10px !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.search-line {
  color: black;
}
