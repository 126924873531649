.receipt {
  width: 76mm;
  font-family: Arial, sans-serif;
  margin: auto;
  text-align: left;
}

.receipt h1 {
  font-size: 24px;
  margin: 5px 0;
  text-align: center;
}

.receipt img {
  height: 50px;
  display: block;
  margin: 0 auto;
}

.receipt p {
  margin: 2px 0;
  padding: 0;
  font-size: 12px;
}

.print-button {
  margin-top: 10px;
  display: block;
}

.print-button button {
  width: 100%;
}
