.internetsub {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(50, 65, 80);
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
}

.main-content {
  width: 100%;
}

.add-subscription {
  display: flex;
  justify-content: center;
}

.add-button {
  background-color: rgb(18, 38, 50);
  box-shadow: 4px 4px 0 rgba(18, 38, 50, 0.5);
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px;
  padding: 10px 15px;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  max-width: 200px;
  transition: all 0.3s ease;
}

@media (max-width: 600px) {
  .tabs-container {
    flex-direction: column;
    overflow-x: auto;
  }
}
