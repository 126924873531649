.maino {
  display: flex;
  height: 100vh;
}
.left {
  flex: 0.6;
  height: 100%;
}
.right {
  flex: 0.4;
  background-color: #bfc6cd;
}
.im {
  margin-left: 40%;
  margin-top: 20px;
}
.upper {
  justify-content: space-between;
}
.texto {
  background-color: #1e2934;
  color: #bfc6cd;
  width: fit-content;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: fit-content;
  padding: 5px;
  padding-right: 18px;
  margin-top: 30px;
}
.lower {
  margin-top: 100px;
  margin-left: 50px;
}
.testf {
  place-content: center;
}
input {
  outline: none;
  border: none;
  background-color: transparent;
  width: fit-content;
  text-align: left;
}

hr {
  width: 70%;
}
.testp {
  width: fit-content;
  border-radius: 30px;
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  color: #bfc6cd;
  background-color: #1e2934;
}
.testd {
  margin-top: 30px;
  justify-content: center;
}
/* hide left section on phones */
@media only screen and (max-width: 600px) {
  .maino {
    flex-direction: column-reverse;
  }
  .left {
    display: none;
  }
  .right {
    flex: 1;
  }
}

/* show left section on larger screens */
@media only screen and (min-width: 601px) {
  .maino {
    flex-direction: row;
  }
  .left {
    display: block;
  }
  .right {
    flex: 0.4;
  }
}
