/* AdminConsole.css */

/* General styles */
.admin-console {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: rgb(50, 65, 80);
  color: white;
}

.right-console,
.left-admin-console {
  margin: 10px 0;
}

.right-console {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right-admin-console {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  background: linear-gradient(to right, rgb(94, 109, 120), rgb(18, 38, 50));
  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
}

.right-admin-console h4 {
  margin: 0;
}

.right-admin-console button {
  padding: 10px 20px;
  margin-top: 10px;
}

.left-admin-console {
  width: 100%;
}

@media (min-width: 600px) {
  .admin-console {
    flex-direction: row;
  }

  .right-console {
    width: 30%;
    margin-right: 10px;
  }

  .left-admin-console {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .admin-console {
    padding: 20px;
  }

  .right-console {
    width: 25%;
  }

  .left-admin-console {
    width: 75%;
  }
}
