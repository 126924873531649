.name{
    
    display: flex;
}
label{
    margin: 20px;
}

.fo{
    text-align: center;
    padding: 10px;
}
.custom-modal-style {

    }
.mod{
    display: contents;
}