/* dealerdetails.css */

.nav-tabs .nav-link.active {
  background-color: transparent;
  color: beige;
  border-radius: 0px !important;
  cursor: pointer;
}

.nav-tabs .nav-link {
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.nav-tabs .nav-link:hover {
  color: rgb(243, 243, 236) !important;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .nav-tabs .nav-link.active {
    border: none !important;
  }
}
