html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  background-color: rgb(50, 65, 80);
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

/* Scrollbar Track */
::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2f4355;
  background-clip: content-box; /* Prevent background from showing behind the scrollbar handle */
}

/* Hover State for Scrollbar Handle */
::-webkit-scrollbar-thumb:hover {
  background-color: #454c5a;
}
