/* navbar.css */
.nav {
  background-color: rgb(18, 38, 50) !important;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjust the placement as needed */
  font-family: Helvetica !important;
}
.nav-left {
  display: flex;
  color: white;
  flex: 0.85;
  font-family: Arial, Helvetica, sans-serif;
}
.nav-right {
  flex: 0.15;
  display: flex;
  justify-content: space-around;
  color: white;

  font-family: Arial, Helvetica, sans-serif;
}
.hamburger {
  background: none;
  border: none;
  cursor: pointer;
}

.bar {
  background-color: aliceblue;
  height: 4px;
  width: 30px;
  margin: 4px 0;
  transition: 0.4s;
}

.bar:hover {
  background-color: #2e79ca; /* Change color on hover if desired */
}

.logout {
  color: aliceblue;
  cursor: pointer;
  border-width: 1px; /* Adjust the width as needed */
  border-color: white; /* Specify the border color */
  border-style: solid;
  background: linear-gradient(to right, rgb(94, 109, 120), rgb(18, 38, 50));
  padding: 5px;
  width: fit-content;
  height: 35px;
  margin: 10px;
  margin-bottom: 20px;
}

.active-tab {
  color: #2e79ca !important;
  background-color: #f8f9fa !important;
}

/* sidebar.css */
.nested-menu {
  margin-left: 20px;
}

.sub-menu-item {
  padding-left: 10px;
}

@media (max-width: 768px) {
  /* Hide the full text and reduce font size */
  .nav-left h3 {
    font-size: 0; /* Hide the text */
  }

  /* Show the "OSS" text and adjust font size */
  .nav-left::after {
    display: none; /* Adjust font size as needed */
  }

  .nav-right {
    display: flex;
    align-items: center;
  }

  h3 {
    margin-right: 10px; /* Add right margin to create space */
  }

  .logout h4 {
    margin-left: 10px; /* Add left margin to create space */
  }
}
