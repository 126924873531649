.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 800px; /* Increased width for wider layout */
  height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  position: relative;
}

.chat-messages {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding-bottom: 10px;
}

.chat-form {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}

.chat-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.chat-input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin-right: 10px;
  font-size: 14px;
  resize: none;
  min-height: 40px;
}

.btn-send {
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.chat-bubble {
  max-width: 75%;
  padding: 10px 15px;
  border-radius: 20px;
  word-wrap: break-word;
  margin: 5px 0;
}

.sent {
  align-self: flex-end;
  background-color: #dcf8c6;
}

.received {
  align-self: flex-start;
  background-color: #fff;
  border: 1px solid #e5e5e5;
}

.chat-content {
  display: flex;
  flex-direction: column;
}

.chat-user {
  font-weight: bold;
  margin-bottom: 5px;
}

.chat-text {
  font-size: 14px;
  margin-bottom: 5px;
}

.chat-time {
  font-size: 12px;
  color: gray;
  text-align: right;
}
