/* styles.css */

.discount-form-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.discount-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.select-input {
  width: 100%;
}

.form-control {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.discount-list {
  margin-top: 30px;
}

.discount-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.discount-table th,
.discount-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.discount-table th {
  background-color: #f4f4f4;
}

.discount-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.discount-table tr:hover {
  background-color: #f1f1f1;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.btn-primary:hover {
  background-color: #0056b3;
}
