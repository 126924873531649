.note-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 20px;
}

.note-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.note-heading {
  font-weight: bold;
  width: 150px; /* Adjust the width as needed */
  margin-right: 10px;
  line-height: 1.5;
}

.note-textarea {
  flex: 1;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: Arial, sans-serif;
  height: 100px; /* Set a fixed height */
  resize: vertical; /* Allow vertical resizing only */
}

.note-submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.note-submit-button:hover {
  background-color: #0056b3;
}

.note-message {
  margin-top: 15px;
}
.note-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.note-input:focus {
  outline: none;
  border-color: #007bff;
}
