.accounting {
  background-color: rgb(50, 65, 80);
  overflow: scroll;
}
.accounting-top {
  display: flex;
  justify-content: space-between;
}
.main_accounting {
  flex: 1;
}
.drawer {
  margin-top: 20px;
  color: white;
  margin-right: 10px;
}
.search-accounting {
  color: #ffffff;
  background-color: #2f4355;
  border: none;
  padding: 8px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 14px;
  width: 100%;
}
