.internetsub {
  display: flex;
  background-color: rgb(50, 65, 80);
  font-family: Arial, Helvetica, sans-serif;
}

.main-content {
  flex: 1;
}

.add-subscription {
  display: flex;
}

.add-button {
  background-color: rgb(18, 38, 50);
  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
  border: none;
  outline: none;
  cursor: pointer;
  margin: 15px;
  width: fit-content;
  padding: 10px;
  transition: all 0.3s !important;
  color: white;
}
