/* styles.css */

/* Default styles */
.selectors-container {
  display: flex;
  gap: 10px;
  width: 50%;
  margin: 10px;
  justify-content: space-around;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .selectors-container {
    flex-direction: column;
    width: 90%; /* Adjust width as needed */
  }
}

/* Additional styles as needed for mobile */
