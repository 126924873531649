.Plans {
  background-color: rgb(50, 65, 80) !important;
  display: flex;
  overflow: scroll;
  height: 90vh;
  font-family: Arial, Helvetica, sans-serif;
}

.main {
  flex: 1;
}
.AddClient {
  background-color: rgb(18, 38, 50);
  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
  border: none;
  outline: none;
  cursor: pointer;
  margin: 15px;
  width: fit-content;
  padding: 10px;
  transition: all 0.3s !important;
}
.AddClient:hover {
  color: #ffffff !important;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3) !important;
}
.search-plan {
  color: #ffffff;
  background-color: #2f4355;
  border: none;
  padding: 8px;
  margin-left: 10px;
  font-size: 14px;
  width: 100%;
}
.PlanTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  background-color: rgb(18, 38, 50);
  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
  padding: 10px;
}
