.zone-dashboard {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.zone-selector {
  margin-bottom: 20px;
}

.zone-selector label {
  margin-right: 10px;
}

.zone-selector select {
  padding: 8px;
  font-size: 16px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.stats-card {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stats-card h3 {
  margin: 0 0 10px;
  font-size: 18px;
  color: #333;
}

.stats-card .card-value {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}