.Clients {
  background-color: rgb(50, 65, 80);
  height: 90vh;
  overflow: scroll;
  display: flex;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
.main {
  flex: 1;
}
.DataGridContainer {
  height: 100%;
  width: 100%;
}
.head {
  text-align: center;
}
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #1c1c1c;
  color: #f5ffff;
  text-align: center;
}
.table-striped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #bdb0b0;
  color: #f5ffff;
  text-align: center;
}

.AddClient {
  background-color: rgb(18, 38, 50);
  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
  color: #b9c5c5;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 15px;
  width: fit-content;
  padding: 10px;
  transition: all 0.3s;
  text-decoration: none;
}
.AddClient:hover {
  color: #ffffff;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

.search-client {
  color: #ffffff;
  background-color: #405668;
  border: none;
  padding: 8px;
  margin-left: 10px;
  font-size: 14px;
  width: 100%;
}
.ClientTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  background-color: rgb(18, 38, 50);
  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
  padding: 10px;
}

.edit-client {
  background-color: #405668;
  color: white;
}
/* Adjust the tab buttons to appear next to each other */
.nav{
  justify-content: space-evenly !important;
}
.nav-tabs {
  display: flex;
  justify-content: start !important; /* Align to the left */
}

.nav-tabs .nav-item {
  margin-right: 10px; /* Add some spacing between tabs */
}

