.print-invoice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.invoice-content {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.invoice-header,
.invoice-footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-header div,
.invoice-footer div {
  width: 48%;
}

.invoice-header h1 {
  font-size: 24px;
  margin: 0;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.invoice-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.invoice-footer {
  font-size: 18px;
}

.invoice-footer div {
  text-align: right;
}

.print-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.print-button:hover {
  background-color: #0056b3;
}
.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.invoice-logo {
  width: 100px; /* Adjust size as needed */
  height: auto;
  position: absolute;
  top: 150px; /* Adjust positioning as needed */
  right: 450px;
}