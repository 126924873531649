.Dashboard {
  overflow: scroll;
  background-color: rgb(50, 65, 80);
  flex: 1;
  width: 100%;
  height: 90vh;
}

.top-cards {
  width: 99%;
  display: flex;
}
.card1 {
  width: 25%;
  margin: 20px;
  height: 150px;
  background-color: rgb(18, 38, 50);
  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
  color: white;
  cursor: pointer;
}
.card-top {
  display: flex;
  height: 60%;
  flex: 1;
  border-bottom: #f3f3f3 1px;
}
.card-bottom {
  height: fit-content;
  text-align: center;
  color: white;
}
.divider {
  border: 1px solid white; /* You can adjust the width and color as needed */
  background-color: white;
  margin: 10px 0; /* Optional margin for spacing */
  width: 100%;
}

.card-right {
  flex: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-left {
  flex: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-right-inner-1 {
  background: linear-gradient(to right, rgb(27, 163, 169), rgb(18, 38, 50));
  width: 60%;
  height: 60%;
}
.card-right-inner-2 {
  background: linear-gradient(to right, rgb(241, 100, 101), rgb(18, 38, 50));
  width: 70%;
  height: 70%;
}
.card-right-inner-3 {
  background: linear-gradient(to right, rgb(250, 169, 77), rgb(18, 38, 50));
  width: 80%;
  height: 80%;
}
.card-right-inner-4 {
  background: linear-gradient(to right, rgb(249, 220, 85), rgb(18, 38, 50));
  width: 80%;
  height: 80%;
}
.card-right-inner-5 {
  background: linear-gradient(to right, rgb(94, 109, 120), rgb(18, 38, 50));
  width: 80%;
  height: 80%;
}

.bottom-cards {
  width: 99%;
  height: 100px;
  display: flex;
  justify-content: space-between;
}
.card2 {
  display: flex;
  width: 33%;
  margin: 15px;

  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
  color: white;
}

.bottom-cards-left-1 {
  flex: 0.3;
  background: linear-gradient(to right, rgb(23, 105, 165), rgb(18, 38, 50));
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-cards-left-2 {
  flex: 0.3;
  background: linear-gradient(to right, rgb(21, 146, 116), rgb(18, 38, 50));
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-cards-left-3 {
  flex: 0.3;
  background: linear-gradient(to right, rgb(94, 109, 120), rgb(18, 38, 50));
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-cards-right {
  flex: 0.7;
  background-color: rgb(18, 38, 50);
  display: flex;
  justify-content: center;
  align-items: center;
}
#chart {
  background-color: rgb(18, 38, 50);
  margin: 15px;
  width: 98%;
  box-shadow: 8px 8px 0 rgba(18, 38, 50, 0.5);
}

@media (max-width: 767px) {
  /* Remove the graph on mobile */
  #chart {
    display: none;
  }

  /* Update the styles for cards in .top to stack vertically and take 100% width */
  .top-cards {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    width: 100%; /* Set cards to take 100% width */
    align-items: center;
  }

  /* Update the styles for cards in .top1 to stack vertically and take 100% width */
  .bottom-cards {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    width: 100%; /* Set cards to take 100% width */
    margin-right: 0; /* Remove right margin */
    align-items: center;
  }
  .card1,
  .card2 {
    width: 80%;
    margin: 10px;
  }
}
